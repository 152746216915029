<svelte:options tag="fds-selectbox" />

<script>
    import {get_current_component} from "svelte/internal"
    let host = get_current_component()

    import { onMount, createEventDispatcher, beforeUpdate } from "svelte";

    import fdsHelper from "@fds-components/fds-helper";
    import {
        name as cname,
        version as componentversion,
    } from "../package.json";
    const path = fdsHelper.get_href();
    /**
     * the list of items  the user can select from
     * @type {[]}
     */
    export let items = [];

    /**
     * function to use to get all items (alternative to providing items)
     * @type {function}
     */
    export let searchFunction = null;

    /**
     * search mode of search function includes or startsWith are implemented
     * @type {string}
     */
    export let searchMode = 'includes'

    /**
     * field of each item that's used for the labels in the list
     * @type {string}
     */
    export let labelfieldname = 'label';
    /**
     * the name of the filed to search by
     * @type {string}
     */
    export let keywordsfieldname = labelfieldname;
    /**
     * default: value - field to use to change the value from the selected item
     * @type {string}
     */
    export let valuefieldname = "value";

    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type === "version") {
            return new Promise((resolve) => {
                resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(type, cname);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;

    
    export let inDialog = false;

    /**
     *  optional function that creates label from the item. If used labelFieldName is ignored
     * @param item
     * @returns {string|*}
     */

    export let labelFunction = function (item) {
        if (item === undefined || item === null) {
            return "";
        }
        if (!labelfieldname) return item["label"] ? item["label"] : item;
        return item[labelfieldname] ? item[labelfieldname] : item;
    };
    /**
     * optional function that creates text to search from the item. If used keywordsfieldname is ignored
     * @param item
     * @returns {string|*}
     */

    export let keywordsFunction = function (item) {
        if (item === undefined || item === null) {
            return "";
        }
        return labelFunction(item);
    };

    /**
     * optional function that derives the value from the selected item. If used valueFieldName is ignored
     * @param item
     * @param force_single
     * @returns {*}
     */

    export let findItem = function (item) {
        if (item === undefined || item === null) {
            return item;
        }

        if (isObjectItems) {
            item = items.find((el1) => el1[valuefieldname] + "" == item);
        } else {
            item = items.find((el1) => el1 + "" == item);
        }
        return item;
    };

    function valueOfItem(item) {
        return isObjectItems ? item[valuefieldname] : item;
    }

    /**
     *  optional function to additionally process the derived keywords from the item
     * @param keywords
     * @returns {*}
     */
    export let keywordsCleanFunction = function (keywords) {
        return keywords;
    };

    /**
     *  optional function to additionally process the user entered text
     * @param userEnteredText
     * @returns {*}
     */

    export let textCleanFunction = function (userEnteredText) {
        return userEnteredText;
    };

    /**
     *  function called before a new value is selected
     * @event beforeChange
     * @param oldSelectedItem
     * @param newSelectedItem
     * @returns {boolean}
     */
    export let beforeChange = function (oldSelectedItem, newSelectedItem) {
        return true;
    };
    /**
     *  function called after new value is selected
     * @event onChange
     * @param newSelectedItem
     */
    export let onChange = function (newSelectedItem) {};
    /**
     *  function called on focus of the input control
     *  @event onFocus
     */
    export let onFocus = function () {};
    /**
     * function called on blur of the input control
     * @event onBlur
     */
    export let onBlur = function () {};
    /**
     * function called when create is true and the user presses enter, the function must return add the created item to the items array and return it
     * @event onCreate
     * @param text
     */
    export let onCreate = function (text) {
        if (debug) {
            console.log("onCreate: " + text);
        }
    };

    // Behaviour properties
    /**
     * set to true to select the first item if the user clears the text and closes the dropdown, defaults to false
     * @type {boolean}
     */
    export let selectfirstifempty = false;
    /**
     * minimum length of search text to perform search, defaults to 1
     * @type {number}
     */
    export let mincharacterstosearch = 1;
    /**
     * maximum number of items to show in the dropdown list, defaults 0 (no limit)
     * @type {number}
     */
    export let maxitemstoshowinlist = 0;
    /**
     * - true to enable accepting of unlisted values
     * @type {boolean}
     */
    export let create = false;
    /**
     * ignores the accents when matching items
     * @type {boolean}
     */
    export let ignoreaccents = true;
    /**
     * all the input keywords should be matched in the item keywords
     * @type {boolean}
     */
    export let matchallkeywords = true;

    /**
     * sorts the items by the number of matchink keywords
     * @type {boolean}
     */
    export let sortbymatchedkeywords = false;
    /**
     * do not allow re-selection after initial selection
     * @type {boolean}
     */
    export let lock = false;
    /**
     * delay to wait after a keypress to search for new items
     * @type {number}
     */

    export let delay = 0;
    /**
     * true to perform local filtering of items, even if searchFunction is provided
     * @type {boolean}
     */
    export let localfiltering = true;

    // UI properties

    /**
     * option to hide the dropdown arrow
     * @type {boolean}
     */
    export let hidearrow = false;
    /**
     * option to show clear selection button
     * @type {boolean}
     */
    export let showclear = false;

    /**
     * option to show loading indicator when the async function is executed
     * @type {boolean}
     */
    export let showloadingindicator = false;
    /**
     * text displayed when no items match the input text
     * @type {string}
     */
    export let noResultsText = "No results found";
    /**
     * text displayed when async data is being loaded
     * @type {string}
     */
    export let loadingtext = "Loading results...";
    /**
     * internal placeholder for input when list is open
     * @type {string}
     */
    let displaytext = undefined;
    /**
     * the text when no option is selected
     * @type {string}
     */
     export let placeholder = undefined;
    /**
     * apply a classname to the control
     * @type {string}
     */
    export let classname = undefined;

    // HTML input UI properties
    /**
     * apply a classname to the input control
     * @type {string}
     */
    export let inputclassname = undefined;
    /**
     * apply a id to the input control
     * @type {string}
     */
    export let inputid = undefined;
    /**
     * generate an HTML input with this name
     * @type {string}
     */
    export let name = undefined;
    /**
     * generate a <select> tag that holds the value
     * @type {string}
     */
    export let selectname = undefined;
    /**
     * apply a id to the <select>
     * @type {string}
     */
    export let selectid = undefined;
    /**
     * add the title to the HTML input
     * @type {string}
     */
    export let title = undefined;
    /**
     * enable the html5 autocompletion to the HTML input
     * @type {boolean}
     */
    export let html5autocomplete = undefined;
    /**
     * make the input readonly
     * @type {boolean}
     */
    export let readonly = undefined;
    /**
     * apply a classname to the dropdown div
     * @type {string}
     */
    export let dropdownclassname = undefined;
    /**
     * adds the disabled tag to the HTML input
     * @type {boolean}
     */
    export let disabled = false;
    /**
     * debug mode
     * @type {boolean}
     */
    export let debug = false;
    /**
     * Dynamic option creation,
     * add item to itemlist
     * @type {boolean}
     */
     export let option_creation = false;
    // --- Public State ----
    /**
     * selected item state
     * @type {string|Array}
     */
    export let selectedItem = undefined;
    let displaytextDummy

    export let value = null;

    let newValue;

    let valueItem = undefined;
    const dispatch = createEventDispatcher();
    const component = get_current_component();
    // --- Internal State ----
    const uniqueId = "sautocomplete-" + Math.floor(Math.random() * 1000);

    // HTML elements
    let input;
    let list;
    let error;

    let isObjectItems = false;
    // UI state
    let smallsize = null;
    let opened = false;
    let loading = false;
    let highlightIndex = -1;
    let highlightcreated=false
    export let text;
    let filteredTextLength = 0;

    // view model
    let filteredListItems;
    let listItems = [];

    // requests/responses counters
    let lastRequestId = 0;
    let lastResponseId = 0;
    //let valuechangeinside = false;

    // other state
    let inputDelayTimeout;

    // -- Reactivity --
    function onSelectedItemChanged(v) {
        let selectedItem = findItem(v);
        // console.log(selectedItem)
        if(!selectedItem) return
        valueItem = valueOfItem(selectedItem);
        // console.log("selectedItem", selectedItem);
        // console.log("valueItem", valueItem);
        console.log(4)
        text = safeLabelFunction(selectedItem);
        text = removeHTMLTags(text)
        
        onChange(selectedItem);
        dispatch("change", selectedItem);
        
        component.dispatchEvent(
            new CustomEvent("change", {
                detail: selectedItem[valuefieldname],
                composed: true, // propagate across the shadow DOM
            })
        );
    }

    $: if (items && items.length){
        if(typeof items[0] === "object"){
            isObjectItems = true;
        }else{
            isObjectItems = false;
        }
    }

    $: {
        if (readonly == "false") readonly = false;
        readonly = !!readonly;
    }

    // $: if (readonly == true) disabled = true;

    $: {
        disabled = disabled.toString();
        if (disabled == "false") disabled = "";
    }

    $: if (value || value=='') {
        onSelectedItemChanged(value);    
    }

    $: if (selectedItem) {
        value = valueOfItem(selectedItem);    
    }

    $: showList =
        opened && ((items && items.length > 0) || filteredTextLength > 0);

    //$: clearable = showclear || ((lock) && selectedItem);
    $: clearable = showclear || (lock && selectedItem);

    $: setProps();
    $: if(items){        
        prepareListItems()
        if(value && !currentlyTyping){
            let item = findItem(value)
            text=safeLabelFunction(item)
        }
    }
    $: if(placeholder){
        displaytext=placeholder
    }

    // themes related
    export let icon_prepend = "";
    export let icon_append = "";
    let prop = {};

    /**
     * The name of the theme
     * @type {string}
     */
    export let theme = "";
    /**
     * The status defined display of element default  normal
     * @type {normal|error|warning|success}
     */
    export let status = "";
    /**
     * predefined size of element default - normal size also default one,lg - large sizesm - small size
     * @type {default|lg|sm}
     */
    export let size = "";

    /**
     * The text field width in CSS value (e.g. 200px), default: 100%
     * @type {string}
     */
    export let width = "";

    onMount(() => {
        setProps();
    });

    beforeUpdate(() => {
        setProps();
    });

    function setProps() {
        if (!window["fds-ui-config"]) {
            window["fds-ui-config"] = {};
        }
        let curtheme = "";
        if (theme) window["fds-ui-config"].inputTheme = theme;
        if (window["fds-ui-config"].inputTheme)
            curtheme = window["fds-ui-config"].inputTheme;
        if (!window["fds-ui-config"].inputTheme) curtheme = "classic";

        window["fds-ui-config"] = {
            ...window["fds-ui-config"],
            inputTheme: curtheme,
        };

        if (window["fds-ui-config"] && !window["fds-ui-config"].getTheme) {
            window["fds-ui-config"].getTheme =
                document.createElement("fds-input-text").getTheme;
        }
        if (window["fds-ui-config"] && window["fds-ui-config"].getTheme) {
            prop = window["fds-ui-config"].getTheme(
                status,
                size,
                disabled,
                theme
            );
        }
        if (width) prop.width = width;
        width = prop.width;
        if (!width) width = "100%";

        if (error) console.log(error);
        prop.paddingLeft = "5px";
        //prop.height = "100%";

        if (icon_prepend) {
            prop.paddingLeft = prop.iconHeight + 15 + "px";
        }
        prop.paddingRight = "5px";
        if (icon_append) {
            // prop.paddingRight = prop.iconHeight + 15 + "px";
        }
        if (prop.height) {
            prop.minHeight = parseInt(prop.height) - 2 + "px";
        }
    }

    function safeStringFunction(theFunction, argument) {
        if (typeof theFunction !== "function") {
            console.error(
                "Not a function: " + theFunction + ", argument: " + argument
            );
        }
        let originalResult;
        try {
            originalResult = theFunction(argument);
        } catch (error) {
            console.warn(
                "Error executing Autocomplete function on value: " +
                    argument +
                    " function: " +
                    theFunction
            );
        }
        let result = originalResult;
        if (
            result === undefined ||
            result === null ||
            (result[labelfieldname] === "" && result[valuefieldname] === "")
        ) {
            result = "";
        }
        if (typeof result !== "string") {
            result = result.toString();
        }
        return result;
    }

    function safeLabelFunction(item) {
        // console.log("safeLabel", item);
        // console.log("labelFunction: " + labelFunction);
        // console.log("safeLabelFunction, item: " + item);
        let s = safeStringFunction(labelFunction, item);
        return s
    }

    function safeKeywordsFunction(item) {
        // console.log("safeKeywordsFunction");
        const keywords = safeStringFunction(keywordsFunction, item);
        let result = safeStringFunction(keywordsCleanFunction, keywords);
        result = result.toLowerCase().trim();
        if (ignoreaccents) {
            result = removeAccents(result);
        }

        if (debug) {
            console.log(
                "Extracted keywords: '" +
                    result +
                    "' from item: " +
                    JSON.stringify(item)
            );
        }
        return result;
    }

    function prepareListItems() {
        setTimeout(() => {
            let timerId;
            if (debug) {
                timerId = `Autocomplete prepare list ${
                    inputid ? `(id: ${inputid})` : ""
                })`;
                console.time(timerId);
                console.log("Prepare items to search");
                console.log("items: " + JSON.stringify(items));
            }

            if (!Array.isArray(items)) {
                console.warn(
                    "Autocomplete items / search function did not return array but",
                    items
                );
                
                items = [];
            }

            const length = items ? items.length : 0;
            listItems = new Array(length);

            if (length > 0) {
                items.forEach((item, i) => {
                    const listItem = getListItem(item);
                    if (listItem == undefined) {
                        console.log("Undefined item for: ", item);
                    }
                    listItems[i] = listItem;
                });
            }

            if (debug) {
                console.log(listItems.length + " items to search");
                console.timeEnd(timerId);
            }
        }, 100);
    }

    function getListItem(item) {
        let label = safeLabelFunction(item);
        if (label === "") label = "&nbsp;";
        return {
            // keywords representation of the item
            keywords: safeKeywordsFunction(item),
            // item label
            label: label,
            // store reference to the origial item
            item: item,
        };
    }

    function prepareUserEnteredText(userEnteredText) {
        if (userEnteredText === undefined || userEnteredText === null) {
            return "";
        }

        const textFiltered = userEnteredText
        .trim();
        // .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, " ")

        filteredTextLength = textFiltered.length;

        if (mincharacterstosearch > 1) {
            if (filteredTextLength < mincharacterstosearch) {
                return "";
            }
        }

        const cleanUserEnteredText = textCleanFunction(textFiltered);
        const textFilteredLowerCase = cleanUserEnteredText.toLowerCase().trim();

        if (debug) {
            console.log(
                "Change user entered text '" +
                    userEnteredText +
                    "' into '" +
                    textFilteredLowerCase +
                    "'"
            );
        }
        return textFilteredLowerCase;
    }

    function numberOfMatches(listItem, searchWords) {
        if (!listItem) {
            return 0;
        }

        const itemKeywords = listItem.keywords;

        let matches = 0;
        searchWords.forEach((searchWord) => {
            if(searchMode=='includes'){
                if (itemKeywords.includes(searchWord)) {
                    matches++;
                }
            }
            if(searchMode=='startsWith'){
                if (itemKeywords.startsWith(searchWord)) {
                    matches++;
                }
            }
        });

        return matches;
    }

    async function search() {
        let timerId;
        if (debug) {
            timerId = `Autocomplete search ${
                inputid ? `(id: ${inputid})` : ""
            })`;
            console.time(timerId);
            console.log("Searching user entered text: '" + text + "'");
        }

        const textFiltered = prepareUserEnteredText(text);
        if (textFiltered === "") {
            if (searchFunction) {
                // we will need to rerun the search
                items = [];
                if (debug) {
                    console.log(
                        "User entered text is empty clear list of items"
                    );
                }
            } else {
                filteredListItems = listItems;
                if (debug) {
                    console.log(
                        "User entered text is empty set the list of items to all items"
                    );
                }
            }
            closeIfMinCharsToSearchReached();
            if (debug) {
                console.timeEnd(timerId);
            }
            return;
        }

        if (!searchFunction) {
            processListItems(textFiltered);
        }

        // external search which provides items
        else {
            lastRequestId = lastRequestId + 1;
            const currentRequestId = lastRequestId;
            loading = true;

            const AsyncGenerator = async function* () {}.constructor;

            // searchFunction is a generator
            if (searchFunction instanceof AsyncGenerator) {
                for await (const chunk of searchFunction(textFiltered)) {
                    // a chunk of an old response: throw it away
                    if (currentRequestId < lastResponseId) {
                        return false;
                    }

                    // a chunk for a new response: reset the item list
                    if (currentRequestId > lastResponseId) {
                        items = [];
                    }

                    lastResponseId = currentRequestId;
                    console.log("hier besser nicht")
                    items = [...items, ...chunk];
                    processListItems(textFiltered);
                }
            }

            // searchFunction is a regular function
            else {
                let result = await searchFunction(textFiltered);

                // If a response to a newer request has been received
                // while responses to this request were being loaded,
                // then we can just throw away this outdated results.
                if (currentRequestId < lastResponseId) {
                    return false;
                }
                lastResponseId = currentRequestId;
                items = result;
                processListItems(textFiltered);
            }

            loading = false;
        }

        if (debug) {
            console.timeEnd(timerId);
            console.log("Search found " + filteredListItems.length + " items");
        }
    }

    function processListItems(textFiltered) {
        prepareListItems();

        // local search
        let tempfilteredListItems;
        if (localfiltering) {
            // var searchWords = textFiltered.split(" ");  #doesnt work with option_creation
            var searchWords = [textFiltered];
            if (ignoreaccents) {
                searchWords = searchWords.map((word) => removeAccents(word));
            }

            tempfilteredListItems = listItems.filter((listItem) => {
                var matches = numberOfMatches(listItem, searchWords);
                if (matchallkeywords) {
                    return matches >= searchWords.length;
                } else {
                    return matches > 0;
                }
            });

            if (sortbymatchedkeywords) {
                tempfilteredListItems = tempfilteredListItems.sort(
                    (obj1, obj2) => {
                        return (
                            numberOfMatches(obj2, searchWords) -
                            numberOfMatches(obj1, searchWords)
                        );
                    }
                );
            }
        } else {
            tempfilteredListItems = listItems;
        }

        const hlfilter = highlightFilter(textFiltered, ["label"]);
        const filteredListItemsHighlighted =
            tempfilteredListItems.map(hlfilter);
        filteredListItems = filteredListItemsHighlighted;
        closeIfMinCharsToSearchReached();
        return true;
    }

    // $: text, search();

    function selectListItem(listItem) {
        if(listItem.created){
            items.push(listItem.item)
        }
        if (disabled) return;
        if (debug) {
            console.log("selectListItem");
        }
        
        if ("undefined" === typeof listItem) {
            // allow undefined items if create is enabled
            if (option_creation) {
                onCreate(text);
                dispatch("create", text);
                component.dispatchEvent(
                    new CustomEvent("create", {
                        detail: text,
                        composed: true, // propagate across the shadow DOM
                    })
                );
                return true;
            }
            if (debug) {
                console.log(`listItem is undefined. Can not select.`);
            }
            return false;
        }
        const newSelectedItem = listItem.item;
        
        if (beforeChange(value, newSelectedItem)) {
            // simple selection
            value = valueOfItem(newSelectedItem);
        }
        console.log(2)
        text= safeLabelFunction(newSelectedItem)
        text = removeHTMLTags(text)
        
        displaytext=''

        return true;
    }

    function selectItem() {
        if (debug) {
            console.log("selectItem");
        }
        const listItem = filteredListItems[highlightIndex];
        if (selectListItem(listItem)) {
            close();
        }
    }

    function up() {
        if (debug) {
            console.log("up");
        }

        open();
        if (highlightIndex > 0) highlightIndex--;
        highlight();
    }

    function down() {
        if (debug) {
            console.log("down");
        }
        open();
        if (highlightIndex < filteredListItems.length - 1) highlightIndex++;
        highlight();
    }

    function highlight() {
        if (debug) {
            console.log("highlight");
        }

        const query = ".selected";
            console.log("Seaching DOM element: " + query + " in " + list);


        const el = list && list.querySelector(query);
        if (el) {
            if (typeof el.scrollIntoViewIfNeeded === "function") {
                if (debug) {
                    console.log("Scrolling selected item into view");
                }
                el.scrollIntoViewIfNeeded();
            } else {
                if (debug) {
                    console.warn(
                        "Could not scroll selected item into view, scrollIntoViewIfNeeded not supported"
                    );
                }
            }
        } else {
            if(option_creation) highlightcreated=true
            if (debug) {
                console.warn("Selected item not found to scroll into view");
            }
        }
    }

    function onListItemClick(listItem) {
        if (disabled) return;
        if (debug) {
            console.log("onListItemClick");
        }

        if (selectListItem(listItem)) {
            close();
        }
    }

    function onDocumentClick(e) {
        if (!e.target) return;
        if (debug) {
            // console.log("onDocumentClick: " + JSON.stringify(e.target));
        }
        let evpath = event.path || (event.composedPath && event.composedPath());
        let clickinsideselectbox = evpath.find((el) => {
            return el.tagName == "FDS-SELECTBOX";
        });
        if (clickinsideselectbox) {
            highlight();
        } else {
            close();
        }
    }

    function onKeyDown(e) {
        

        if (disabled) return;
        if (debug) {
            console.log("onKeyDown");
        }

        let key = e.key;
        if (key === "Tab" && e.shiftKey) key = "ShiftTab";
        const fnmap = {
            Tab: opened ? close.bind(this) : null,
            ShiftTab: opened ? close.bind(this) : null,
            ArrowDown: down.bind(this),
            ArrowUp: up.bind(this),
            Escape: onEsc.bind(this),
            Backspace: null,
        };
        const fn = fnmap[key];
        if (typeof fn === "function") {
            e.preventDefault();
            fn(e);
        }
    }

    function onKeyPress(e) {
        if (debug) {
            console.log("onKeyPress");
        }

        if (e.key === "Enter" && opened) {
            e.preventDefault();
            onEnter();
        }
    }

    function onEnter() {
        selectItem();
        component.shadowRoot.querySelector('input').blur()
    }

    function onInput(e) {
        if (debug) {
            console.log("onInput");
        }
        console.log(5)
        //text = e.target.value;
        if (inputDelayTimeout) {
            clearTimeout(inputDelayTimeout);
        }

        if (delay) {
            inputDelayTimeout = setTimeout(processInput, delay);
        } else {
            processInput();
        }
    }

    function unselectItem(tag) {
        if (disabled) return;
        if (debug) {
            console.log("unselectItem", tag);
        }
        value = value.filter((i) => i !== tag);
        input.focus();
    }

    function processInput() {
        if(option_creation && text && text != '' && !findItem(text)){
            let i
            if(isObjectItems){
                i = {}
                i[labelfieldname]=text
                i[valuefieldname]=text
            }else{
                i = text
            }
            let e = getListItem(i)
            e.created=true
            listItems.push(e)
            console.log(listItems)
        }

        if (search()) {
            highlightIndex = 0;
            open();
        }
    }

    function onInputClick() {
        if (debug) {
            console.log("onInputClick");
        }
        
        resetListToAllItemsAndOpen();
        
    }

    function onClickOpenClose(){
        console.log('TEST')
        if(!opened){
            resetListToAllItemsAndOpen();
            opened=true;
        }else{
            close()
            opened=false;
        }
    }

    function onEsc(e) {
        if (debug) {
            console.log("onEsc");
        }

        //if (text) return clear();
        e.stopPropagation();
        if (opened) {
            input.focus();
            close();
        }
    }

    function onBackspace(e) {
        if (debug) {
            console.log("onBackspace");
        }

        unselectItem(selectedItem[selectedItem.length - 1]);
    }

    let currentlyTyping=false
    function onFocusInternal() {
        currentlyTyping=true
         if (debug) {
            console.log("onFocus");
        }
        let i = findItem(value)
        displaytextDummy=displaytext
        displaytext=safeLabelFunction(i)
        displaytext = removeHTMLTags(displaytext)

        text=''
        onFocus();
        dispatch("focus", "");
        component.dispatchEvent(
            new CustomEvent("focus", {
                detail: "",
                composed: true, // propagate across the shadow DOM
            })
        );

        resetListToAllItemsAndOpen();
    }

    function removeHTMLTags(t){
        t = t.replace(/<\/?[^>]+(>|$)/g, "");
        return t
    }

    function onBlurInternal() {
        currentlyTyping=false

        setTimeout(() => {

        if (debug) {
            console.log("onBlur");
        }

        let i = findItem(value)
        console.log(3)
        text = safeLabelFunction(i)
        text = removeHTMLTags(text)
        
        if(displaytextDummy && text==''){
            displaytext=displaytextDummy
        }else{
            displaytext=''
        }

        onBlur();
        dispatch("blur", "");

        component.dispatchEvent(
            new CustomEvent("blur", {
                detail: "",
                composed: true, // propagate across the shadow DOM
            })
        );
            close()
        }, 250);
    }

    function resetListToAllItemsAndOpen() {
        if (debug) {
            console.log("resetListToAllItemsAndOpen");
        }
        
        if(inDialog){
            let list = host.shadowRoot.querySelector('.autocomplete-list');
            list.style.width=input.offsetWidth+'px';
            list.style.left=parseInt(input.getBoundingClientRect().x)+'px';
        }
        if (true || !text) {
            filteredListItems = listItems;
        }

        // When an async component is initialized, the item list
        // must be loaded when the input is focused.
        else if (!listItems.length && selectedItem && searchFunction) {
            search();
        }

        open();

        // find selected item
        if (value) {
            if (debug) {
                console.log(
                    "Searching currently selected item: " +
                        JSON.stringify(value)
                );
            }
            for (let i = 0; i < listItems.length; i++) {
                const listItem = listItems[i];

                if ("undefined" === typeof listItem) {
                    if (debug) {
                        console.log(`listItem ${i} is undefined. Skipping.`);
                    }
                    continue;
                }
                if (debug) {
                    console.log("Item " + i + ": " + JSON.stringify(listItem));
                }
                if (value == listItem.item) {
                    highlightIndex = i;
                    if (debug) {
                        console.log(
                            "Found selected item: " +
                                i +
                                ": " +
                                JSON.stringify(listItem)
                        );
                    }
                    highlight();
                    break;
                }
            }
        }
    }

    function open() {
        if (disabled) return;
        if (debug) {
            console.log("open");
        }

        // check if the search text has more than the min chars required
        if (isMinCharsToSearchReached()) {
            return;
        }

        opened = true;
    }

    function close() {
        if (debug) {
            console.log("close");
        }
        opened = false;
        loading = false;

        if (!text && selectfirstifempty) {
            highlightFilter = 0;
            selectItem();
        }
    }

    function isMinCharsToSearchReached() {
        return (
            mincharacterstosearch > 1 &&
            filteredTextLength < mincharacterstosearch
        );
    }

    function closeIfMinCharsToSearchReached() {
        if (isMinCharsToSearchReached()) {
            close();
        }
    }

    function clear() {
        if (debug) {
            console.log("clear");
        }

        text = "";
        selectedItem = undefined;

        setTimeout(() => {
            input.focus();
            close();
        });
    }

    function highlightFilter(keywords, fields) {
        keywords = keywords.split(/\s+/g);
        return (item) => {
            const newItem = Object.assign({ highlighted: {} }, item);
            if (fields) {
                fields.forEach((field) => {
                    if (newItem[field] && !newItem.highlighted[field]) {
                        newItem.highlighted[field] = newItem[field];
                    }
                    if (newItem.highlighted[field]) {
                        keywords.forEach((keyword) => {
                            keyword = keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
                            const reg = new RegExp("(" + keyword + ")", "ig");
                            newItem.highlighted[field] = newItem.highlighted[
                                field
                            ].replace(reg, "<b>$1</b>");
                        });
                    }
                });
            }
            return newItem;
        };
    }

    function removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    function isConfirmed(listItem) {
        if (!value) {
            return false;
        }
        return listItem == value;
    }
</script>
{#if !readonly}

<div
    style="--round:{prop.round};--shadow: {prop.shadow};
           --width: {width};--height:{prop.height};height:{prop.height};--minHeight:{prop.minHeight};--font-size:{prop.fontSize};
           --border-color:{prop.borderColor};--background-color:{prop.backgroundColor};--focus-color:{prop.focusColor};--color:{prop.color};
           --padding-left:{prop.paddingLeft};--padding-right:{prop.paddingRight}; --overflow:{prop.overflow}"
    class="{prop.className} maindiv {classname ? classname : ''}
  {hidearrow || !items.length ? 'hide-arrow' : ''}
  autocomplete select is-fullwidth {uniqueId} {smallsize
        ? 'smallsize'
        : ''} {icon_append ? 'hasrighticon' : ''}"
    class:show-clear={clearable}
    class:is-loading={showloadingindicator && loading}
>
    <select name={selectname} id={selectid} bind:value={valueItem}>
        {#if valueItem}
            <option {valueItem} selected>{text}</option>
        {/if}
    </select>
    <div
        class="  input-container container"
        style="--round:{prop.round};--shadow: {prop.shadow};
           --width: {width};--height:{prop.height};--minHeight:{prop.minHeight};--font-size:{prop.fontSize};
           --border-color:{prop.borderColor};--background-color:{prop.backgroundColor};--focus-color:{prop.focusColor};--color:{prop.color};
           --padding-left:{prop.paddingLeft};--padding-right:{prop.paddingRight}; --overflow:{prop.overflow};"
    >
            <input
                type="text"
                class=" realinput {inputclassname
                    ? inputclassname
                    : ''}   autocomplete-input  "
                id={inputid ? inputid : ""}
                autocomplete={html5autocomplete ? "on" : "off"}
                placeholder={displaytext}
                {name}
                {disabled}
                {title}
                bind:this={input}
                bind:value={text}
                on:input={onInput}
                on:focus={onFocusInternal}
                on:blur={onBlurInternal}
                on:keydown={onKeyDown}
                on:click={onInputClick}
                on:keypress={onKeyPress}
                style="min-width:30%;--round:{prop.round};--shadow: {prop.shadow};
           --width: {width};--height:{prop.height};--font-size:{prop.fontSize};
           --border-color:{prop.borderColor};--background-color:{prop.backgroundColor};--focus-color:{prop.focusColor};--color:{prop.color};
           --padding-left:{prop.paddingLeft};--padding-right:{prop.paddingRight}"
            />
        
        {#if prop.theme === "underline"}
            <span
                class="underline-animation"
                style="--focus-color:{prop.focusColor}"
            />
            <span
                class="underline-animation-const"
                style="--border-color:{prop.borderColor};"
            />
        {/if}
        {#if opened }
            <span on:click={onClickOpenClose} class="close_item" />
        {:else}
            <span on:click={onClickOpenClose} class="open_item" />
        {/if}
        {#if icon_prepend}<fds-icon
                name={icon_prepend}
                height={prop.iconHeight}
                class="iconPrepend"
                style="--color:{prop.iconColor}"
            />{/if}
        {#if icon_append}<fds-icon
                name={icon_append}
                height={prop.iconHeight}
                class="iconAppend"
                style="--color:{prop.iconColor}"
            />{/if}

        {#if clearable}
            <span on:click={clear} class="autocomplete-clear-button"
                >&#10006;</span
            >
        {/if}
    </div>
    <div
        class="{dropdownclassname
            ? dropdownclassname
            : ''} autocomplete-list {showList ? '' : 'hidden'}  {inDialog ? 'inDialog' : ''}
    is-fullwidth"
        bind:this={list}
    >
        {#if filteredListItems && filteredListItems.length > 0}
            {#each filteredListItems as listItem, i}
                {#if listItem && (maxitemstoshowinlist <= 0 || i < maxitemstoshowinlist)}
                    {#if listItem}
                        <div
                            style="--selectColor:{prop.selectColor};--font-size:{prop.fontSize};"
                            class="autocomplete-list-item {i === highlightIndex
                                ? 'selected'
                                : ''}"
                            class:confirmed={isConfirmed(listItem.item)}
                            on:click={() => onListItemClick(listItem)}
                            on:pointerenter={() => {
                                highlightIndex = i;
                            }}
                        >
                            <div
                                name="item"
                                item={listItem.item}
                                label={listItem.highlighted
                                    ? listItem.highlighted.label
                                    : listItem.label}
                            >
                                {#if listItem.highlighted}
                                    {@html listItem.highlighted.label}
                                {:else}
                                    {@html listItem.label}
                                {/if}
                            </div>
                        </div>
                    {/if}
                {/if}
            {/each}

            {#if maxitemstoshowinlist > 0 && filteredListItems.length > maxitemstoshowinlist}
                <div class="autocomplete-list-item-no-results">
                    ...{filteredListItems.length - maxitemstoshowinlist} results
                    not shown
                </div>
            {/if}
        {:else if (noResultsText && !option_creation)}
            <div class="autocomplete-list-item-no-results">
                <div name="no-results" {noResultsText}>{noResultsText}</div>
            </div>
        {:else if loading && loadingtext}
            <div class="autocomplete-list-item-loading">
                <div name="loading" {loadingtext}>{loadingtext}</div>
            </div>
        {/if}

    </div>
</div>
{:else}
            <div style="height: 100%; vertical-align: center">
                {#if text }
                    {text}
                {/if}
            </div>
        {/if}
<svelte:window on:click={onDocumentClick} />

<style>
    .autocomplete {
        min-width: 50px;
        display: inline-block;
        max-width: 100%;
        position: relative;
        vertical-align: top;
        height: var(--height);
    }


    .close_item:not(.hide-arrow):not(.is-loading) {
        border: 3px solid transparent;
        border-radius: 2px;
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: 0.625em;
        margin-top: -0.4375em;
        /*pointer-events: none;*/
        position: absolute;
        top: 60%;
        -webkit-transform: rotate(-225deg);
        transform: rotate(-225deg);
        -webkit-transform-origin: center;
        transform-origin: center;
        width: 0.625em;
        border-color: #3273dc;
        right: 1.125em;
        z-index: 4;
        cursor: pointer;
    }

    .open_item:not(.hide-arrow):not(.is-loading) {
        border: 3px solid transparent;
        border-radius: 2px;
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: 0.625em;
        margin-top: -0.4375em;
        /*pointer-events: none;*/
        position: absolute;
        top: 50%;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: center;
        transform-origin: center;
        width: 0.625em;
        border-color: #3273dc;
        right: 1.125em;
        z-index: 4;
        cursor: pointer;
    }

    .hasrighticon .open_item:not(.hide-arrow):not(.is-loading) {
        right: 2.825em;
    }

    .autocomplete.show-clear:not(.hide-arrow)::after {
        right: 2.3em;
    }

    .autocomplete * {
        box-sizing: border-box;
    }
    .autocomplete-input {
        font: inherit;
        width: 100%;
        height: 100%;
        padding: 5px 0px;
    }

    .autocomplete:not(.hide-arrow) .autocomplete-input {
        padding-right: 2em;
    }
    .autocomplete.show-clear:not(.hide-arrow) .autocomplete-input {
        padding-right: 3.2em;
    }
    .autocomplete.hide-arrow.show-clear .autocomplete-input {
        padding-right: 2em;
    }

    .autocomplete-list {
        background: #fff;
        position: absolute;
        width: 100%;
        overflow-y: auto;
        z-index: 99;
        padding: 10px 0;
        left: 0;
        border: 1px solid #999;
        max-height: calc(15 * (1rem + 10px) + 15px);
        user-select: none;
    }
    .autocomplete-list:empty {
        padding: 0;
    }
    .autocomplete-list-item {
        padding: 5px 15px;
        color: #333;
        cursor: pointer;
        line-height: 1;
        font-size: var(--font-size);
    }

    .autocomplete-list-item.confirmed {
        /*
        background-color: #789fed;
        color: #fff;
        */
        background-color: var(--selectColor);
        color: #4a4a4a;
    }

    .autocomplete-list-item.selected {
        background-color: var(--selectColor);
        /* color: #fff;*/
        color: #4a4a4a;
    }
    .autocomplete-list-item-create.selected {
        background-color: var(--selectColor);
        /* color: #fff;*/
        color: #4a4a4a;
    }
    .autocomplete-list-item-no-results {
        padding: 5px 15px;
        color: #999;
        line-height: 1;
    }
    .autocomplete-list-item-create {
        padding: 5px 15px;
        line-height: 1;
        cursor: pointer;
    }
    .autocomplete-list-item-loading {
        padding: 5px 15px;
        line-height: 1;
    }

    .autocomplete-list.hidden {
        display: none;
    }

    .autocomplete-list.inDialog {
        position: fixed;
    }

    .autocomplete.show-clear .autocomplete-clear-button {
        cursor: pointer;
        display: block;
        text-align: center;
        position: absolute;
        right: 0.1em;
        padding: 0.3em 0.6em;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 4;
    }

    .autocomplete:not(.show-clear) .autocomplete-clear-button {
        display: none;
    }

    .autocomplete select {
        display: none;
    }

    .input-container {
        position: relative;
    }

    .autocomplete-input {
        display: flex;
        width: 100%;
        flex: 1 1 50px;
        min-width: 3em;
        border: none;
        box-shadow: none;
        background: none;
    }

    .tags {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .tags .tag {
        margin-bottom: 0.5rem;
    }

    .tags .tag:not(:last-child) {
        margin-right: 0.5rem;
    }

    .tags:last-child {
        margin-bottom: -0.5rem;
    }

    .tags:not(:last-child) {
        margin-bottom: 1rem;
    }

    .tags.are-medium .tag:not(.is-normal):not(.is-large) {
        font-size: 1rem;
    }

    .tags.are-large .tag:not(.is-normal):not(.is-medium) {
        font-size: 1.25rem;
    }

    .tags.is-centered {
        justify-content: center;
    }

    .tags.is-centered .tag {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }

    .tags.is-right {
        justify-content: flex-end;
    }

    .tags.is-right .tag:not(:first-child) {
        margin-left: 0.5rem;
    }

    .tags.is-right .tag:not(:last-child) {
        margin-right: 0;
    }

    .tags.has-addons .tag {
        margin-right: 0;
    }

    .tags.has-addons .tag:not(:first-child) {
        margin-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .tags.has-addons .tag:not(:last-child) {
        /* border-top-right-radius: 0; */
        /* border-bottom-right-radius: 0; */
    }

    .tag:not(body) {
        align-items: center;
        background-color: var(--selectColor);
        /*border-radius: 4px;*/
        color: #4a4a4a;
        display: inline-flex;
        font-size: 0.75em;
        height: 2em;
        justify-content: center;
        line-height: 1.5;
        padding-left: 0.75em;
        padding-right: 0.75em;
        white-space: nowrap;
    }

    .tag:not(body) .delete {
        margin-left: 0.25rem;
        margin-right: -0.375rem;
    }

    .tag:not(body) .icon:first-child:not(:last-child) {
        margin-left: -0.375em;
        margin-right: 0.1875em;
    }

    .tag:not(body) .icon:last-child:not(:first-child) {
        margin-left: 0.1875em;
        margin-right: -0.375em;
    }

    .tag:not(body) .icon:first-child:last-child {
        margin-left: -0.375em;
        margin-right: -0.375em;
    }

    .tag:not(body).is-delete {
        margin-left: 1px;
        padding: 0;
        position: relative;
        width: 2em;
    }

    .tag:not(body).is-delete::before,
    .tag:not(body).is-delete::after {
        background-color: currentColor;
        content: "";
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform-origin: center center;
    }

    .tag:not(body).is-delete::before {
        height: 1px;
        width: 50%;
    }

    .tag:not(body).is-delete::after {
        height: 50%;
        width: 1px;
    }

    .tag:not(body).is-delete:hover,
    .tag:not(body).is-delete:focus {
        background-color: #e8e8e8;
    }

    .tag:not(body).is-delete:active {
        background-color: #dbdbdb;
    }

    .tag:not(body).is-rounded {
        border-radius: 9999px;
    }

    input:focus {
        outline: none !important;
    }

    /*Themes */
    * {
        box-sizing: border-box;
    }
    .container {
        /*position: relative;*/
        /*height: var(--height);*/
        min-height: var(--minHeight);
        display: block;
        overflow: var(--overflow);
    }
    .iconPrepend {
        position: absolute;
        left: -25px;
        top: 50%;
        transform: translateY(-50%);
    }
    .iconAppend {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .padded{
        padding: var(--padding) !important;
    }

    .input {
        color: var(--color);
        background: var(--background-color);
        font-size: var(--font-size);
        height: 100%;
        min-height: var(--minHeight);
        width: var(--width);
        padding: 0;
        border: 1px solid;
        padding-left: var(--padding-left);
        padding-right: var(--padding-right);
        position: relative;
        display: inline-block;
        border-color: var(--border-color);
        border-radius: var(--round);
        box-shadow: var(--shadow);
        outline: none !important;
    }
    .focusDefault:focus-within {
        border-color: var(--focus-color);
    }
    .readonly {
        padding-left: var(--padding-left);
        padding-right: var(--padding-right);
        font-size: var(--font-size);
        height: var(--height);
        width: var(--width);
    }
    .maindiv {
        width: var(--width);
    }
    .underline {
        border: 0;
    }

    .underline input {
        border: 0;
        /*border-bottom: 2px solid var(--border-color);*/
    }

    .underline-animation-const {
        border-bottom: 2px solid var(--border-color);
        bottom: 0px;
        left: 0;
        position: absolute;
        width: 100%;
        height: 2px;
    }

    .underline-animation {
        transition: all 0.5s;
        bottom: 0px;
        left: -100%;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--focus-color);
    }
    .container > .realinput:focus + .underline-animation {
        left: 0;
        display: inline-block;
        z-index: 2;
    }
    .realinput {
        position: relative;
    }
    .focusDefault:focus {
        border-color: var(--focus-color);
    }
</style>
